@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    src: url(https://uploads-ssl.webflow.com/629c94f2f665fd8f65f2537f/629c95f69384116dead9befe_SpaceGrotesk-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    src: url(https://uploads-ssl.webflow.com/629c94f2f665fd8f65f2537f/629c95f6334549ae30681df1_SpaceGrotesk-Medium.woff) format('woff');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    src: url(https://uploads-ssl.webflow.com/629c94f2f665fd8f65f2537f/629c95f6a211981242bdf534_SpaceGrotesk-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 400;
    src: url(https://uploads-ssl.webflow.com/629c94f2f665fd8f65f2537f/629c96573be4ddfbffcc7ac0_SpaceMono-Regular.woff) format('woff');

}

@font-face {
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 600;
    src: url(https://uploads-ssl.webflow.com/629c94f2f665fd8f65f2537f/629c965715e0656b9dd9dbfa_SpaceMono-Bold.woff) format('woff');
}
