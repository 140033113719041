.node circle {
    fill: white;
    stroke: darkblue;
    stroke-width: 2px;
}

.node text {
    font-size: 11px;
    font-family: 'Space Grotesk', sans-serif;
    background-color: black;
    fill: black;
}

.node {
    cursor: pointer;
}

path.link {
    fill: none;
    stroke: lightgrey;
    stroke-width: 1.5px;
}